import styled from '@emotion/styled';
import { Box, Link, Typography } from '@mui/material';
import NextLink from 'next/link';
import { FC } from 'react';
import { OptimizedImage } from '../components/optimized-image';

const LoginContainer = styled.div`
  width: 600px;
  max-width: 100%;
  min-height: 100vh;
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 2rem;
  background: rgba(255, 255, 255, 0.95);
  position: relative;

  ${props => props.theme.breakpoints.down('sm')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const Login = styled.main`
  width: 100vw;
  min-height: 100vh;
`;

const LoginBackground = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: calc(100vw - 600px);
  background-color: #eee;
  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const LoginLayout: FC = ({ children }) => (
  <Login>
    <LoginBackground />
    <LoginContainer>
      <Box textAlign="center">
        <NextLink href="/" passHref>
          <Link underline="none">
            <OptimizedImage src="/images/logo-veroo.svg" height="60" ext="svg" />
          </Link>
        </NextLink>
      </Box>
      <Box mb={6} mt={4}>
        <Box my={1}>
          <Typography variant="h3" color="textSecondary" align="center" component="h2">
            Painel
          </Typography>
        </Box>
        <Typography variant="subtitle1" color="textSecondary" align="center">
          Aqui o seu sorriso é liberado!
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" align="center">
          Gerencie tudo o que precisa no seu painel
        </Typography>
      </Box>
      <Box pb={4}>{children}</Box>
    </LoginContainer>
  </Login>
);
