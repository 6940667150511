import { Box, Typography } from '@mui/material';
import { SEO } from '@web/common/components/seo/seo';
import { SignInContainer } from '@web/common/components/sign-in';
import { LoginLayout } from '@web/common/layout/login';
import { useRouter } from 'next/router';
import { FC } from 'react';

const Login: FC = props => {
  const { query } = useRouter();
  const nextUrl = typeof query.next === 'string' ? query.next : '/admin/subscription';
  return (
    <LoginLayout {...props}>
      <SEO title="Login" />
      <Box mt={1} mb={2}>
        <Typography variant="subtitle1" color="textSecondary" align="center">
          Para acessar o sistema escolha o método abaixo.
        </Typography>
      </Box>
      <SignInContainer
        ssoOptions={{
          googleNextUrl: nextUrl,
          bottomDividerText: 'ou entre com',
        }}
        hasAgreementTerms
        hasRegisterButton
        defaultNextUrl="/admin/subscription"
      />
    </LoginLayout>
  );
};

export default Login;
